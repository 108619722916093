<template>
  <div class="pa-6">
    <v-card elevation="2">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categorysState.isLoading">
            <span>Создать</span>
            <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
          </v-btn>

          <v-btn class="ml-4 my-2 mr-2" depressed color="primary" @click="isUploadOpen = true">
            <span>Загрузить</span>
            <v-icon class="ml-2" small>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        <v-sheet max-width="750">
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="category in categorys" :key="'categorys_'+category.id" :class="category._show ? '' : 'd-none'">
              <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="category.medRecordTemplateCategorys.length===0" class="text-center caption pa-1">Не найдено</div>
                <v-expansion-panels v-if="category.medRecordTemplateCategorys.length>1" accordion class="expansionPanelContentExpanded">
                  <v-expansion-panel v-for="medRecordTemplateCategory in category.medRecordTemplateCategorys" :key="'categorys1_'+medRecordTemplateCategory.id">
                    <v-expansion-panel-header class="font-weight-medium">{{ medRecordTemplateCategory.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item v-for="medRecordTemplate in medRecordTemplateCategory.medRecordTemplates" :key="'medRecordTemplate_'+medRecordTemplate.id" @click="medRecordCreate(medRecordTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ medRecordTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="medRecordTemplateCategory.medRecordTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <template v-else>
                  <template v-for="medRecordTemplateCategory in category.medRecordTemplateCategorys" >
                      <v-list v-if="medRecordTemplateCategory.medRecordTemplates" :key="'categorys1_'+medRecordTemplateCategory.id">
                        <v-list-item v-for="medRecordTemplate in medRecordTemplateCategory.medRecordTemplates" :key="'medRecordTemplate_'+medRecordTemplate.id" @click="medRecordCreate(medRecordTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ medRecordTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="medRecordTemplateCategory.medRecordTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>               
                  </template>
                </template>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-menu>
     
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categorysState.isLoading || mediaCreateState.isLoading">
            <span>Создать из документа</span>
            <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-sheet max-width="750">
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="category in mediaTemplateCategories" :key="'categorys_'+category.id">
              <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="category.subcategories.length===0&&category.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                <v-expansion-panels accordion class="expansionPanelContentExpanded">
                  <v-expansion-panel v-for="subcategory in category.subcategories" :key="'categorys1_'+subcategory.id">
                    <v-expansion-panel-header class="font-weight-medium">{{ subcategory.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item v-for="mediaTemplate in subcategory.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="typeof subcategory.mediaTemplates !=='undefined'&&subcategory.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                </v-expansion-panels>
                   <v-list v-if="category.mediaTemplates.length!==0">
                      <v-list-item v-for="mediaTemplate in category.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                        <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
              </v-expansion-panel-content>           
            </v-expansion-panel>
          </v-expansion-panels>
          <v-list v-if="mediaTemplates.length>0">
            <v-list-item v-for="mediaTemplate in mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
              <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-menu>

      <v-data-table
          :headers="headers"
          :items="medRecords"         
          :loading="mediaRecordState.isLoading"
          dense
          :height="height"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item="{ item }">
          
          <tr @click="typeof item.medRecordTemplate !== 'undefined' ? medRecordId=item.id : mediaId=item.id">
            <td class="text-no-wrap">{{item.id}}</td>
            <td>{{item.name!=null&&item.name!='' ? item.name : typeof item.medRecordTemplate !== 'undefined' ? item.medRecordTemplate.name : '-'}} <v-icon v-if="(typeof item.medRecordTemplate === 'undefined')" small class="ml-1" title="Документ">mdi-file</v-icon></td>
            <td>{{item.comment}}</td>
            <td class="text-no-wrap">
              <div v-if="misDesktopOnline&&typeof item.medRecordTemplate === 'undefined'">
                <v-btn small depressed @click.stop="misDesktopOpenFile(item)"><v-icon class="mr-2" small>mdi mdi-open-in-new</v-icon>Открыть локально</v-btn>
              </div>
            </td>
            <td>
              <v-tooltip v-if="item.status==='unactive'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-file-alert</v-icon>
                </template>
                <span>Черновик</span>
              </v-tooltip>
            </td>
            <td class="text-no-wrap">
              <v-avatar size="32" class="mr-2" v-if="typeof item.userAuthor.imageUrl !== 'undefined'&&item.userAuthor.imageUrl!==null">
                <img :src="item.userAuthor.imageUrl"/>
              </v-avatar>
              {{$tools.userFullName(item.userAuthor)}}
              
            </td>
            <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
            <td class="text-no-wrap">
              <v-chip
                v-if="item.isSent"
                class="font-weight-medium"
                color="green"
                text-color="white"
                small
              >
                Отправлено
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <med-record-update :med-record-id.sync="medRecordId" @medRecordCopied="medRecordCopied" :userId="$router.currentRoute.params.id"/>

    <media-view :media-id.sync="mediaId" :user-id="$router.currentRoute.params.id" :userName="userName" @updateList="mediaRecordLoad()"/>

    <uploader
        :dialog.sync="isUploadOpen"
        :multiple="true"
        :url="'/api/media/media/upload/?mediaDirId=2&userId='+$router.currentRoute.params.id"
        @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import MedRecordUpdate from "@/views/MedRecord/MedRecordUpdate";
import MediaView from "@/views/Media/MediaView";
import Uploader from "@/components/Uploader";
import axios from "axios";

import {
  Entity_MedRecord,
  Entity_MedRecordTemplate,
  Entity_MediaTemplateCategory,
  Entity_MediaTemplate,
  Entity_Media,
  Entity_User,
  Entity_MediaDir
} from "../../../../EntityStoreCacheService";

export default {
  components: {MedRecordUpdate, MediaView, Uploader},
  props:['userName'],
  data: () => ({
    categorysState: new State(),
    categorys: [],
    headers: [
      { text: 'ID', value: 'id', sortable: true, class:'text-no-wrap', width:'auto'},
      { text: 'Название', value: 'medRecordTemplate.name', sortable: true, class:'text-no-wrap'},
      { text: 'Комментарий', sortable: false},
      { text: '', sortable: false},
      { text: '', sortable: false},
      { text: 'Автор', value: 'userAuthor', sortable: false, class:'text-no-wrap'},
      { text: 'Дата создания', value: 'created', sortable: true, class:'text-no-wrap'},
      { text: 'Отправка на Email', sortable: false},
    ],
    mediaRecordState: new State(),
    mediaRecordCreateState: new State(),
    medRecords:[],
    medRecordId:null,
    medias: [],
    mediaId: null,
    isUploadOpen: false,
    height:null,

    mediaTemplateCategories: [],
    mediaCreateState: new State(),
    mediaCreateErrorState: new State(),

    mediaTemplates: [],
    misDesktopOnline:false,
  }),
  mounted() {
    this.height = window.innerHeight - 200-12;
    this.loadTemplatesCategories();
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });
  },
  watch: {
    medRecordId(){
      if (this.medRecordId===null)
      this.mediaRecordLoad();
    }
  },
  methods:{
    loadTemplatesCategories(){
      this.categorysState.stateLoading();
      Api.service.medRecord.medRecordTemplateCategorys(1, 1000,  false,(v)=>{

        let categorys = [];
        v.data.forEach((v)=>{
          v._show = false;
          if (v.medRecordTemplateCategorys.length!==0)
            v._show = true;
          categorys.push(v);
        })
        this.categorys = categorys;
        this.mediaRecordLoad();
      }, ()=>{
        this.categorysState.stateError();
      });


      let mediaTemplateCategory =  new Entity_MediaTemplateCategory()
      .selects(Entity_MediaTemplateCategory.id)
      .selects(Entity_MediaTemplateCategory.name)
      .selects(Entity_MediaTemplateCategory.status)
      .selects(Entity_MediaTemplateCategory.subcategories)


      //mediaTemplate
      .selects([Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.id].join('.'))
      .selects([Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.name].join('.'))
      .selects([Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.sort].join('.'))

      //subcategories
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.id].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.name].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.status].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.sort].join('.'))
     
      //subcategory mediaTemplates
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.id].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.name].join('.'))

      .page(1).onPage(3000)

      //filter by MediaDir
      .filterAnd([Entity_MediaTemplateCategory.entity, Entity_MediaTemplateCategory.status].join('.')+'=?','active')
      //.filterAnd([Entity_MediaTemplateCategory.entity, Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.status].join('.')+'=?','active')
      .filterAnd([Entity_MediaTemplateCategory.entity, Entity_MediaTemplateCategory.mediaDir, Entity_MediaDir.id].join('.')+' NOT IN (?)',[1])

      

      mediaTemplateCategory.request((data)=>{
  
        let mediaCategories = [];
        data.items.forEach(cat=>{     
            mediaCategories.push(cat);
            if (cat.subcategories.length>0) {
              cat.subcategories.forEach(s=>{
                const existingIndex = mediaCategories.findIndex(item => item.id === s.id);

                if (existingIndex !== -1) { //удаляем копии, если они найдены в подразделах
                    mediaCategories.splice(existingIndex, 1);
                }
                
              })          
            }
        });
        //sort
        function sortMediaTemplates(category) {

            if (category.mediaTemplates) {
                category.mediaTemplates.sort((a, b) => a.sort - b.sort);
            }

            if (category.subcategories && category.subcategories.length > 0) {
                category.subcategories = category.subcategories.map(subcategory => sortMediaTemplates(subcategory));
            }

            return category;
        }

        const sortedData = mediaCategories.map(category => sortMediaTemplates(category));

        this.mediaTemplateCategories = sortedData;

        this.categorysState.stateSuccess();
      }, (e)=>{
        console.error(e.response.data.message);
        this.categorysState.stateError();
      })

    },
    mediaRecordLoad(){
      this.mediaRecordState.stateLoading();

      const promises = [];
      const medRecords = new Promise((resolve) => {  
        let medRecordRequest = new Entity_MedRecord()
          .selects(Entity_MedRecord.id)
          .selects(Entity_MedRecord.created)
          .selects(Entity_MedRecord.isSent)
          .selects(Entity_MedRecord.name)
          .selects(Entity_MedRecord.status)
          .selects(Entity_MedRecord.comment)
       

          //user
          .selects([Entity_MedRecord.user,Entity_User.id].join('.'))
          .selects([Entity_MedRecord.user,Entity_User.firstName].join('.'))
          .selects([Entity_MedRecord.user,Entity_User.lastName].join('.'))
          .selects([Entity_MedRecord.user,Entity_User.secondName].join('.'))

          //user author
          .selects([Entity_MedRecord.userAuthor, Entity_User.id].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.firstName].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.lastName].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.secondName].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.imageUrl].join('.'))

          //medRecordTemplate
          .selects([Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.id].join('.'))
          .selects([Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.name].join('.'))

          .filterAnd([Entity_MedRecord.entity, Entity_MedRecord.user, Entity_User.id ].join('.')+' =?',this.$router.currentRoute.params.id)
          .filterAnd([Entity_MedRecord.entity, Entity_MedRecord.status].join('.')+'!=?','deleted')


          .page(1).onPage(10000)

          medRecordRequest.request((data)=>{
              resolve(data.items);
          }, (e)=>{
            console.error(e.response.data.message);
          })
      });

      promises.push(medRecords);

      const media = new Promise((resolve) => {
        let mediaRequest = new Entity_Media()
        .selects(Entity_Media.id)
        .selects(Entity_Media.created)
        .selects(Entity_Media.isSent)
        .selects(Entity_Media.name)
        .selects(Entity_Media.status)

        //user
        .selects([Entity_Media.user,Entity_User.id].join('.'))
        .selects([Entity_Media.user,Entity_User.firstName].join('.'))
        .selects([Entity_Media.user,Entity_User.lastName].join('.'))
        .selects([Entity_Media.user,Entity_User.secondName].join('.'))

        //user
        .selects([Entity_Media.userAuthor, Entity_User.id].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.firstName].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.lastName].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.secondName].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.imageUrl].join('.'))


        .filterAnd([Entity_Media.entity, Entity_Media.user, Entity_User.id ].join('.')+' =?',this.$router.currentRoute.params.id)
        .filterAnd([Entity_Media.entity, Entity_Media.mediaDir, Entity_MediaDir.id ].join('.')+' NOT IN (?)',[1])
        .filterAnd([Entity_Media.entity, Entity_Media.status].join('.')+'!=?','deleted')

        .page(1).onPage(10000)

        mediaRequest.request((data)=>{
            resolve(data.items);
        }, (e)=>{
          console.error(e.response.data.message);
        })
      });
      promises.push(media);


      Promise.all(promises).then((result) => {
        let medRecordsAndMedia = [];
        result.forEach(arr=>{
          medRecordsAndMedia = medRecordsAndMedia.concat(arr);
        })

        //sort by created
        let sortedArray = medRecordsAndMedia.slice().sort(function(a, b) {
            return new Date(b.created) - new Date(a.created);
        });


        this.medRecords = sortedArray;

        this.mediaRecordState.stateSuccess();
      }).catch((error) => {
        console.error('Error:', error);
        this.mediaRecordState.stateError(error)
      }); 
          
    },
    medRecordCreate(medRecordTemplateId){
      this.mediaRecordCreateState.stateLoading();
      Api.service.medRecord.medRecordCreate(medRecordTemplateId, this.$router.currentRoute.params.id, (data)=>{
        this.mediaRecordCreateState.stateSuccess();
        this.medRecordId = data.id;
        this.loadTemplatesCategories();
      }, ()=>{
        this.mediaRecordCreateState.stateError();
      });
    },
    medRecordCopied(id){
      this.loadTemplatesCategories();
      this.medRecordId = id;
    },
    uploadComplete(){
      this.loadTemplatesCategories();
    },

    mediaCreateAction(mediaTemplateId){
      this.mediaCreateState.stateLoading();
      axios.post(Api.host+'/media/mediaTemplate/'+mediaTemplateId+'/mediaCreate/', {
        userId:this.$router.currentRoute.params.id
      })
          .then(r=>{
            this.mediaCreateState.stateSuccess();
            this.loadTemplatesCategories();
            this.$tools.misDesktopStatus((v)=>{
              if(v){
                this.$tools.misDesktopOpenFile(
                    r.data.fileName,
                    r.data.name + r.data.fileName.substring(r.data.fileName.lastIndexOf("."), r.data.fileName.length),
                    "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
                    {id: r.data.id},
                    ()=>{
                      this.$store.commit('alertSuccess', 'Файл открывается локально');
                    }, ()=>{
                      this.$store.commit('alertError', 'Не удалось открыть файл локально');
                    }
                );
              } else {
                this.mediaId = r.data.id;
              }
            });
          })
          .catch((e)=>{
            console.log(e);
            this.mediaCreateState.stateError();
          });
    },
    misDesktopOpenFile(v){
      axios.get(Api.host + '/media/media/' + v.id + '/').then(r1 => {
        let mediaData = r1.data;
        this.$tools.misDesktopOpenFile(
            mediaData.filename,
            mediaData.name + mediaData.filename.substring(mediaData.filename.lastIndexOf("."), mediaData.length),
            "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
            {
              id: v.id,
            },
            ()=>{
              this.$store.commit('alertSuccess', 'Файл открывается локально');
            }, ()=>{
              this.$store.commit('alertError', 'Не удалось открыть файл локально');
            }
        );
      });
    }
  }
}
</script>
